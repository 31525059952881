import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const checkIsElementsOverlapping = (stickyElement: HTMLDivElement, elementsBefore: HTMLDivElement[]) => {
  const stickyElementRect = stickyElement.getBoundingClientRect();

  for (const elementBefore of elementsBefore) {
    const stickyBeforeRect = elementBefore.getBoundingClientRect();

    const elementsIsOverlapping = !(
      stickyElementRect.right < stickyBeforeRect.left ||
      stickyElementRect.left > stickyBeforeRect.right ||
      stickyElementRect.bottom < stickyBeforeRect.top ||
      stickyElementRect.top > stickyBeforeRect.bottom
    );

    const stickyElementIsBelow = stickyElementRect.bottom < stickyBeforeRect.bottom;

    if (elementsIsOverlapping || stickyElementIsBelow) {
      return true;
    }
  }

  return false;
};

type StickyElementKey =
  | 'depot-year-switcher'
  | 'action-layout'
  | 'scroll-progress-bar'
  | 'summary'
  | 'solution-home-tabs';

interface StickyElement {
  stickyElement: HTMLDivElement;
  adjustPaddingOfNextSiblingElement?: boolean;
  marginTopOffset: number;
  minimumWindowWidth: number;
}

interface AddStickyElementArguments {
  key: StickyElementKey;
  stickyElement: HTMLDivElement;
  adjustPaddingOfNextSiblingElement?: boolean;
  marginTopOffset?: number;
  minimumWindowWidth?: number;
}

interface StickyHandler {
  stickyHeader: HTMLDivElement | null;
  setStickyHeader: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  removeStickyElement: (key: string) => void;
  addStickyElement: ({ stickyElement, key, adjustPaddingOfNextSiblingElement }: AddStickyElementArguments) => void;
}

const StickyHandlerContext = createContext<StickyHandler | null>(null);

export const StickyHandlerProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const [stickyHeader, setStickyHeader] = useState<HTMLDivElement | null>(null);

  const [stickyElements, setStickyElements] = useState<{
    [key: string]: StickyElement;
  }>({});

  const whenNormal = useRef<{
    [key: string]: {
      scrollY: number;
      style: {
        marginTop: string;
        position: string;
        left: string;
        top: string;
      };
    };
  }>({});

  const getElementsBefore = useCallback(
    (key: StickyElementKey) => {
      let elementsBefore = [];

      if (stickyHeader) {
        elementsBefore.push(stickyHeader);
      }

      if (key === 'action-layout' && stickyElements['depot-year-switcher']) {
        elementsBefore.push(stickyElements['depot-year-switcher'].stickyElement);
      }

      return elementsBefore;
    },
    [stickyElements, stickyHeader],
  );

  const handleStickiness = useCallback(() => {
    if (stickyHeader) {
      const { scrollY } = window;

      const keys = Object.keys(stickyElements);

      for (const key of keys) {
        if (window.innerWidth < stickyElements[key].minimumWindowWidth) {
          continue;
        }

        const stickyElement = stickyElements[key].stickyElement;

        const { nextElementSibling, parentNode } = stickyElement;

        if (parentNode instanceof HTMLElement) {
          const parentNodeComputedStyle = getComputedStyle(parentNode);

          stickyElement.style.width = `${
            parentNode.offsetWidth -
            parseFloat(parentNodeComputedStyle.paddingLeft) -
            parseFloat(parentNodeComputedStyle.paddingRight)
          }px`;
        }

        const elementsBefore = getElementsBefore(key as StickyElementKey);

        const elementsIsOverlapping = checkIsElementsOverlapping(stickyElement, elementsBefore);

        const computedStyle = getComputedStyle(stickyElement);

        const { position, left, marginTop, top } = computedStyle;

        if (position !== 'fixed' && elementsIsOverlapping) {
          whenNormal.current = {
            ...whenNormal.current,
            [key]: {
              scrollY,
              style: {
                position,
                left,
                top,
                marginTop,
              },
            },
          };

          if (stickyElements[key].adjustPaddingOfNextSiblingElement && nextElementSibling instanceof HTMLElement) {
            nextElementSibling.style.paddingTop = `${stickyElement.offsetHeight + parseFloat(marginTop)}px`;
          }

          const elementsBeforeHeight = elementsBefore.reduce(
            (acc, elementBefore) => acc + elementBefore.offsetHeight,
            0,
          );

          stickyElement.style.marginTop = `${elementsBeforeHeight + stickyElements[key].marginTopOffset}px`;

          stickyElement.style.position = 'fixed';
          stickyElement.style.left = 'auto';
          stickyElement.style.top = '0';
        } else if (whenNormal.current[key] && scrollY < whenNormal.current[key].scrollY) {
          if (stickyElements[key].adjustPaddingOfNextSiblingElement && nextElementSibling instanceof HTMLElement) {
            nextElementSibling.style.paddingTop = '0';
          }

          stickyElement.style.marginTop = whenNormal.current[key].style.marginTop;
          stickyElement.style.position = whenNormal.current[key].style.position;
          stickyElement.style.left = whenNormal.current[key].style.left;
          stickyElement.style.top = whenNormal.current[key].style.top;
        }
      }
    }
  }, [stickyHeader, stickyElements, whenNormal, getElementsBefore]);

  useEffect(() => {
    window.addEventListener('scroll', handleStickiness);

    return () => {
      window.removeEventListener('scroll', handleStickiness);
    };
  }, [handleStickiness]);

  useEffect(() => {
    window.addEventListener('resize', handleStickiness);

    return () => {
      window.removeEventListener('resize', handleStickiness);
    };
  }, [handleStickiness]);

  useEffect(() => {
    handleStickiness();
  }, [handleStickiness, location]);

  const addStickyElement = useCallback(
    ({
      stickyElement,
      key,
      adjustPaddingOfNextSiblingElement,
      marginTopOffset = 0,
      minimumWindowWidth = 608,
    }: AddStickyElementArguments) => {
      setStickyElements((prev) => ({
        ...prev,
        [key]: {
          stickyElement,
          adjustPaddingOfNextSiblingElement,
          marginTopOffset,
          minimumWindowWidth,
        },
      }));
    },
    [],
  );

  const removeStickyElement = useCallback((key: string) => {
    setStickyElements((prev) => {
      if (prev[key]) {
        let result = { ...prev };
        delete result[key];
        return result;
      }

      return prev;
    });
  }, []);

  const value = useMemo(
    () => ({
      stickyHeader,
      setStickyHeader,
      addStickyElement,
      removeStickyElement,
    }),
    [stickyHeader, setStickyHeader, addStickyElement, removeStickyElement],
  );

  return <StickyHandlerContext.Provider value={value}>{children}</StickyHandlerContext.Provider>;
};

export const useStickyHandler = () => useContext(StickyHandlerContext);

export const useMakeElementsSticky = ({
  actionLayoutRef,
  depotYearSwitcherRef,
  scrollProgressBarRef,
  summaryRef,
  solutionHomeTabsRef,
}: {
  actionLayoutRef?: React.MutableRefObject<HTMLDivElement | null>;
  depotYearSwitcherRef?: React.MutableRefObject<HTMLDivElement | null>;
  scrollProgressBarRef?: React.MutableRefObject<HTMLDivElement | null>;
  summaryRef?: React.MutableRefObject<HTMLDivElement | null>;
  solutionHomeTabsRef?: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const stickyHandler = useStickyHandler();

  useEffect(() => {
    if (stickyHandler) {
      if (depotYearSwitcherRef?.current) {
        stickyHandler.addStickyElement({
          key: 'depot-year-switcher',
          adjustPaddingOfNextSiblingElement: true,
          stickyElement: depotYearSwitcherRef.current,
        });
      }

      if (actionLayoutRef?.current) {
        stickyHandler.addStickyElement({
          key: 'action-layout',
          stickyElement: actionLayoutRef.current,
          adjustPaddingOfNextSiblingElement: true,
          marginTopOffset: -1,
        });
      }

      if (scrollProgressBarRef?.current) {
        stickyHandler.addStickyElement({
          key: 'scroll-progress-bar',
          stickyElement: scrollProgressBarRef.current,
          adjustPaddingOfNextSiblingElement: true,
          minimumWindowWidth: 1376,
        });
      }

      if (summaryRef?.current) {
        stickyHandler.addStickyElement({
          key: 'summary',
          stickyElement: summaryRef.current,
          adjustPaddingOfNextSiblingElement: true,
          minimumWindowWidth: 1376,
        });
      }

      if (solutionHomeTabsRef?.current) {
        stickyHandler.addStickyElement({
          key: 'solution-home-tabs',
          stickyElement: solutionHomeTabsRef.current,
          adjustPaddingOfNextSiblingElement: true,
        });
      }
    }
  }, [actionLayoutRef, depotYearSwitcherRef, scrollProgressBarRef, summaryRef, solutionHomeTabsRef, stickyHandler]);

  useEffect(
    () => () => {
      if (stickyHandler) {
        stickyHandler.removeStickyElement('depot-year-switcher');
        stickyHandler.removeStickyElement('action-layout');
        stickyHandler.removeStickyElement('scroll-progress-bar');
        stickyHandler.removeStickyElement('summary');
      }
    },
    [stickyHandler],
  );
};
