import { MOCKING_ENABLED } from 'app/config';

export async function enableMocking() {
  if (!MOCKING_ENABLED) {
    return;
  }

  const { worker } = await import('./browser');

  return worker.start({ onUnhandledRequest: 'bypass' });
}
