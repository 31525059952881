export const generalInfo = {
  header: 'General information',
  description:
    'Begin by selecting the currency for your calculations and deciding whether you want to compare the TCO of the BEV candidate to that of vehicles with other propulsion systems.',
};

export const vehicleCostsInfo = {
  header: 'Vehicle costs',
  description: 'Define the costs related to the vehicle, such as type of financing, vehicle price, residual value.',
};

export const energyCostsInfo = {
  header: 'Energy costs',
  description: 'Define the energy costs related to the operation of the vehicle.',
};

export const operationalCostsInfo = {
  header: 'Operational costs',
  description: 'Define the operational costs for the vehicle, such as insurance, taxes and maintenance.',
};
