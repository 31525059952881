import { useMemo } from 'react';
import { roundTo } from '@optimization/ssi-common';

const useAggregateMultipleValues = <T, K extends keyof T>(
  vehicles: T[],
  property: K,
  roundingRequired: boolean = false,
) =>
  useMemo(() => {
    let properties;
    if (roundingRequired)
      properties = vehicles.map((e) => {
        const value = e[property];
        if (value === undefined || typeof value !== 'number') return '';

        return roundTo(value, 2).toString();
      });
    else
      properties = vehicles.map((e) => {
        const value = e[property];
        if (typeof value === 'number' || typeof value === 'string') return value.toString();

        return '';
      });

    let orderedProperties = orderByMostCommon(properties.filter((result) => result !== ''));

    if (orderedProperties.length === 0) return '0';
    if (orderedProperties.length === 1) return orderedProperties.toString();
    if (orderedProperties.length > 3) orderedProperties = orderedProperties.slice(0, 3);

    return `${orderedProperties.join(', ')}...`;
  }, [property, roundingRequired, vehicles]);

export default useAggregateMultipleValues;

const orderByMostCommon = (array: string[]) => {
  const frequencyMap: Record<string, number> = {};

  array.forEach((entry) => (frequencyMap[entry] = (frequencyMap[entry] || 0) + 1));

  const orderedArray = Object.entries(frequencyMap)
    .sort((a, b) => b[1] - a[1])
    .map(([entry]) => entry);

  return orderedArray;
};
