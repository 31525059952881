import classes from './style.module.scss';
import classNames from 'classnames';
import useOnChangeFieldValue from '../useOnChangeFieldValue';
import { FormValues, SetFormValue } from '../..';
import { DebouncedTextField } from '@optimization/ssi-common';
import { TcoVehicleVM } from '@optimization/sa-common';
import useAggregateMultipleValues from '../useAggregateMultipleValues';

interface Props {
  className?: string;
  disabled?: boolean;
  fromCostsHeader: string;
  vehicles: TcoVehicleVM[];
  formValues: FormValues;
  setFormValue: SetFormValue;
  isMultiple: boolean;
}

const EnergyCostsFields = ({
  className,
  disabled,
  fromCostsHeader,
  vehicles,
  formValues,
  setFormValue,
  isMultiple,
}: Props) => {
  const onChangeBevEnergyCost = useOnChangeFieldValue({
    attribute: 'bevEnergyCost',
    setFormValue,
  });

  const onChangeDieselEnergyCost = useOnChangeFieldValue({
    attribute: 'comparisonEnergyCost',
    setFormValue,
  });

  const onChangeDieselEnergyConsumption = useOnChangeFieldValue({
    attribute: 'comparisonEnergyConsumption',
    setFormValue,
  });

  const onChangeDieselAdBlueCost = useOnChangeFieldValue({
    attribute: 'comparisonAdBlueCost',
    setFormValue,
  });

  const onChangeDieselAdBlueConsumption = useOnChangeFieldValue({
    attribute: 'comparisonAdBlueConsumption',
    setFormValue,
  });

  const totalEc = useAggregateMultipleValues(vehicles, 'TotalEc', true);
  const comparisonConsumption = useAggregateMultipleValues(vehicles, 'Consumption', true);

  return (
    <div className={classNames(classes['energy-costs-fields'], className)}>
      <div className={classNames(classes['header-1'], 'tds-detail-04')}>BEV</div>
      <div className={classNames(classes['header-2'], 'tds-detail-04')}>{fromCostsHeader}</div>
      <div className={classNames('tds-detail-04', classes['label-1'])}>Cost</div>
      <DebouncedTextField
        className={classes['field-1-1']}
        type="number"
        value={formValues.bevEnergyCost}
        size="lg"
        suffixText={`${formValues.currency}/kWh`}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevEnergyCost}
        label=""
      />
      <DebouncedTextField
        className={classes['field-1-2']}
        type="number"
        value={formValues.comparisonEnergyCost}
        size="lg"
        suffixText={
          formValues.comparisonEngineType === 'NaturalGas' ? `${formValues.currency}/kg` : `${formValues.currency}/l`
        }
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselEnergyCost}
        label=""
      />
      <div className={classNames('tds-detail-04', classes['label-2'])}>Consumption</div>
      <DebouncedTextField
        className={classes['field-2-1']}
        type="text"
        value={totalEc}
        size="lg"
        suffixText="kWh/km"
        min={0}
        disabled
        noMinWidth
        label=""
        onChange={() => {}}
      />
      <DebouncedTextField
        className={classes['field-2-2']}
        type={isMultiple ? 'text' : 'number'}
        value={comparisonConsumption}
        size="lg"
        suffixText={formValues.comparisonEngineType === 'NaturalGas' ? 'kg/100km' : 'l/100km'}
        min={0}
        noMinWidth
        onChange={onChangeDieselEnergyConsumption}
        disabled={isMultiple}
        label=""
      />
      <div className={classNames('tds-detail-04', classes['label-3'])}>Ad blue cost</div>
      <DebouncedTextField
        className={classes['field-3-1']}
        type="number"
        size="lg"
        suffixText="N/A"
        min={0}
        disabled
        noMinWidth
        value=""
        label=""
        onChange={() => {}}
      />
      <DebouncedTextField
        className={classes['field-3-2']}
        type="number"
        size="lg"
        suffixText={formValues.comparisonEngineType === 'NaturalGas' ? 'N/A' : `${formValues.currency}/l`}
        disabled={disabled || formValues.comparisonEngineType === 'NaturalGas'}
        min={0}
        noMinWidth
        onChange={onChangeDieselAdBlueCost}
        value={fromCostsHeader === 'Gas' ? '' : formValues.comparisonAdBlueCost}
        label=""
      />
      <div className={classNames('tds-detail-04', classes['label-4'])}>Ad blue consumption</div>
      <DebouncedTextField
        className={classes['field-4-1']}
        type="number"
        size="lg"
        suffixText="N/A"
        min={0}
        disabled
        noMinWidth
        value=""
        label=""
        onChange={() => {}}
      />
      <DebouncedTextField
        className={classes['field-4-2']}
        type="number"
        size="lg"
        suffixText={formValues.comparisonEngineType === 'NaturalGas' ? 'N/A' : 'l/100km'}
        disabled={disabled || formValues.comparisonEngineType === 'NaturalGas'}
        min={0}
        noMinWidth
        onChange={onChangeDieselAdBlueConsumption}
        value={formValues.comparisonEngineType === 'NaturalGas' ? '' : formValues.comparisonAdBlueConsumption}
        label=""
      />
    </div>
  );
};

export default EnergyCostsFields;
