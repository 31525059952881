import CardTegel from 'common/components/CardTegel';
import onboardingSteps from 'app/config/onboardingSteps';
import useLoadingText from 'common/hooks/useLoadingText';
import { Button, ButtonLink, Loading, Modal } from '@optimization/ssi-common';
import { TdsIcon } from '@scania/tegel-react';
import { useAppSelector } from 'app/hooks';
import { useLocation } from 'react-router-dom';
import { useGetUserQuery, useUpdateOnboardingMutation } from 'app/services/solution';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface Tour {
  card: ReactNode;
  takeTheTour: ({ onlyModalSteps }: { onlyModalSteps: boolean }) => void;
}

const TourContext = createContext<Tour | null>(null);

export const TourProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const userQuery = useGetUserQuery(undefined, { skip: Boolean(!accessToken) });

  const [updateOnboarding, updateOnboardingState] = useUpdateOnboardingMutation();

  const [stepIndex, setStepIndex] = useState(0);
  const [onlyModalSteps, setOnlyModalSteps] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const filteredSteps = useMemo(
    () => onboardingSteps.filter((step) => (onlyModalSteps ? step.inModal : true)),
    [onlyModalSteps],
  );

  const takeTheTour = useCallback(({ onlyModalSteps: onlyModalStepsLocal }: { onlyModalSteps: boolean }) => {
    setStepIndex((prev) => (onlyModalStepsLocal ? 0 : prev + 1));
    setOnlyModalSteps(onlyModalStepsLocal);
    setShowModal(true);
  }, []);

  const onPrevModalStep = useCallback(() => {
    if ((onlyModalSteps && stepIndex === 0) || (!onlyModalSteps && stepIndex === 1)) {
      setShowModal(false);
    }

    setStepIndex((prev) => Math.max(prev - 1, 0));
  }, [stepIndex, onlyModalSteps]);

  const onNextModalStep = useCallback(() => {
    setStepIndex((prev) => Math.min(prev + 1, filteredSteps.length - 1));
  }, [filteredSteps]);

  const onCloseModalTour = useCallback(() => {
    setStepIndex(0);
    setShowModal(false);
  }, []);

  useEffect(() => {
    if (!onlyModalSteps && stepIndex) {
      setShowModal(stepIndex < filteredSteps.length - 1);
    }
  }, [filteredSteps, onlyModalSteps, stepIndex]);

  useEffect(() => {
    const stepsWithImageUrl = onboardingSteps.filter((step) => step.imageUrl);

    for (const stepWithImage of stepsWithImageUrl) {
      const image = new Image();
      image.src = stepWithImage.imageUrl || '';
    }
  }, []);

  useEffect(() => {
    setOnlyModalSteps(false);
  }, [location]);

  const onboardingCompleted = useMemo(
    () => Boolean(userQuery.isSuccess && userQuery.data?.OnboardingCompleted),
    [userQuery],
  );

  const isLastStep = useMemo(() => filteredSteps.length - 1 === stepIndex, [filteredSteps, stepIndex]);

  const modal = useMemo(
    () =>
      showModal ? (
        <Modal
          size="md"
          variant="primary"
          cancelText="Back"
          ctaOrder="reversed"
          ctaConfirmText={isLastStep && onlyModalSteps ? 'Close' : 'Next'}
          ctaConfirmSubmitDataTestid="button-onboarding-tour-next-step"
          disabled={updateOnboardingState.isLoading}
          onClose={onCloseModalTour}
          onCancel={onPrevModalStep}
          ctaConfirmSubmit={isLastStep && onlyModalSteps ? onCloseModalTour : onNextModalStep}
          header={
            <div className="flex items-center gap-16">
              {filteredSteps?.[stepIndex]?.modalIcon}
              <div>
                <h6 className="tds-headline-06">
                  Step {onlyModalSteps ? stepIndex + 1 : stepIndex}/
                  {onlyModalSteps ? filteredSteps.length : filteredSteps.length - 1}
                </h6>
                <h6 className="tds-headline-06 tds-text-grey-400 mt-spacing-4">{filteredSteps?.[stepIndex]?.header}</h6>
              </div>
            </div>
          }
        >
          {filteredSteps?.[stepIndex]?.imageUrl && (
            <div className="tds-u-mb2 ml-negative-spacing-16 mr-negative-spacing-16">
              <img className="w-full" alt={filteredSteps[stepIndex].header} src={filteredSteps[stepIndex].imageUrl} />
            </div>
          )}
          <p style={{ minHeight: '74px' }}>{filteredSteps?.[stepIndex]?.body}</p>
        </Modal>
      ) : null,
    [
      filteredSteps,
      isLastStep,
      onlyModalSteps,
      stepIndex,
      showModal,
      updateOnboardingState,
      onNextModalStep,
      onCloseModalTour,
      onPrevModalStep,
    ],
  );

  const card = useMemo(
    () =>
      showModal || onboardingCompleted ? null : (
        <div data-testid="onboarding-tour-card" className="tds-mode-light mt-spacing-32 max-w-368">
          <CardTegel
            header={`Step ${stepIndex}/${onboardingSteps.length - 1}`}
            subheader={filteredSteps?.[stepIndex]?.header}
            thumbnail={<TdsIcon name="home" size="32px" />}
            actions={
              <div className="flex gap-16">
                {isLastStep ? (
                  <ButtonLink
                    text="Go to documentation"
                    url="/product-manual/solution-management"
                    variant="secondary"
                    size="sm"
                    target="_blank"
                  />
                ) : (
                  <Button
                    text="No thanks"
                    variant="secondary"
                    size="sm"
                    disabled={updateOnboardingState.isLoading}
                    onClick={updateOnboarding}
                  />
                )}
                <Button
                  dataTestid={isLastStep ? undefined : 'button-take-the-tour'}
                  disabled={updateOnboardingState.isLoading}
                  text={isLastStep ? 'Close' : 'Take the tour'}
                  size="sm"
                  onClick={isLastStep ? updateOnboarding : () => takeTheTour({ onlyModalSteps: false })}
                />
              </div>
            }
          >
            {onboardingSteps?.[stepIndex]?.body}
          </CardTegel>
        </div>
      ),
    [
      filteredSteps,
      isLastStep,
      onboardingCompleted,
      showModal,
      stepIndex,
      updateOnboardingState,
      takeTheTour,
      updateOnboarding,
    ],
  );

  const value = useMemo(
    () => ({
      card,
      takeTheTour,
    }),
    [card, takeTheTour],
  );

  const loadingText = useLoadingText({
    isClosingTour: updateOnboardingState.isLoading,
  });

  return (
    <TourContext.Provider value={value}>
      <Loading
        isLoading={updateOnboardingState.isLoading}
        isError={updateOnboardingState.isError}
        loadingText={loadingText}
      />
      {modal}
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);
