import { financialEvaluationVehicleTypeOptions, TcoVehicleVM, TcoVehicleType } from '@optimization/sa-common';
import { FormValues } from '..';

export const convertMultipleResponseToFormValues = (vehicles: TcoVehicleVM[]) => {
  const defaultFormValues: FormValues = {
    currency: 'EUR',
    yearsOfOwnership: '0',
    bevInsuranceCost: '0',
    bevTaxesCost: '0',
    bevRoadToll: '0',
    bevMaintenanceCost: '0',
    bevEngineType: 'BEV',
    bevEnergyCost: '0',
    bevFinanceType: 'Cash',
    bevVehiclePrice: '0',
    bevInterestRate: '0',
    bevResidual: '0',
    bevGovernmentSubsidies: '0',
    comparisonInsuranceCost: '0',
    comparisonTaxesCost: '0',
    comparisonRoadtoll: '0',
    comparisonMaintenanceCost: '0',
    comparisonAdBlueCost: '0',
    comparisonAdBlueConsumption: '0',
    comparisonEngineType: TcoVehicleType[TcoVehicleType.Diesel],
    comparisonEnergyCost: '0',
    comparisonEnergyConsumption: '0',
    comparisonFinanceType: 'Cash',
    comparisonVehiclePrice: '0',
    comparisonInterestRate: '0',
    comparisonResidual: '0',
    comparisonGovernmentSubsidies: '0',
  };

  if (vehicles.some((v) => v.TcoCalculations !== undefined)) {
    const filteredVehicles = vehicles.filter((v) => v.TcoCalculations !== undefined);

    const comparisonEngine =
      financialEvaluationVehicleTypeOptions.find(
        (x) => x.value === filteredVehicles[0].TcoCalculations?.ComparisonVehicle?.EngineType,
      )?.value ?? 'Diesel';

    const formValues: FormValues = {
      ...defaultFormValues,
      currency:
        filteredVehicles[0]!.TcoCalculations?.Currency &&
        filteredVehicles.every((v) => v.TcoCalculations?.Currency === filteredVehicles[0].TcoCalculations?.Currency)
          ? filteredVehicles[0]!.TcoCalculations.Currency
          : 'EUR',
      bevFinanceType:
        filteredVehicles[0]!.TcoCalculations?.BevVehicle?.FinancingType &&
        filteredVehicles.every(
          (v) =>
            v.TcoCalculations?.BevVehicle?.FinancingType ===
            filteredVehicles[0].TcoCalculations?.BevVehicle?.FinancingType,
        )
          ? filteredVehicles[0]!.TcoCalculations.BevVehicle?.FinancingType
          : 'Cash',
      comparisonEngineType:
        filteredVehicles[0]!.TcoCalculations?.ComparisonVehicle?.EngineType &&
        filteredVehicles.every(
          (v) =>
            v.TcoCalculations?.ComparisonVehicle?.EngineType ===
            filteredVehicles[0].TcoCalculations?.ComparisonVehicle?.EngineType,
        )
          ? comparisonEngine
          : 'Diesel',
      comparisonFinanceType:
        filteredVehicles[0]!.TcoCalculations?.ComparisonVehicle?.FinancingType &&
        filteredVehicles.every(
          (v) =>
            v.TcoCalculations?.ComparisonVehicle?.FinancingType ===
            filteredVehicles[0].TcoCalculations?.ComparisonVehicle?.FinancingType,
        )
          ? filteredVehicles[0]!.TcoCalculations.ComparisonVehicle?.FinancingType
          : 'Cash',
    };
    return formValues;
  }
  return defaultFormValues;
};
