import { ReactNode } from 'react';
import { TdsCard } from '@scania/tegel-react';

interface Props {
  actions?: ReactNode;
  bodyImage?: ReactNode;
  children?: ReactNode;
  header: string;
  subheader?: string;
  thumbnail?: ReactNode;
}

const CardTegel = ({ actions, bodyImage, children, header, subheader, thumbnail }: Props) => (
  <TdsCard header={header} subheader={subheader} image-placement="above-header">
    {thumbnail && <div slot="thumbnail">{thumbnail}</div>}
    {bodyImage && <div slot="body-image">{bodyImage}</div>}
    {children && <div slot="body">{children}</div>}
    {actions && <div slot="actions">{actions}</div>}
  </TdsCard>
);

export default CardTegel;
