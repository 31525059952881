import Drawer from './Drawer';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

const DRAWER_WIDTH_DESKTOP = 500;

interface IDrawer {
  drawerWidth: number;
  activateDrawer: (content: ReactNode) => void;
  deactivateDrawer: () => void;
}

const DrawerContext = createContext<IDrawer | null>(null);

export const DrawerProvider = ({ children }: { children: ReactNode }) => {
  const [content, setContent] = useState<ReactNode>();

  // Sometimes rare times window.outerWidth seems to be zero
  const drawerWidth = useMemo(() => Math.min(window.outerWidth, DRAWER_WIDTH_DESKTOP) || DRAWER_WIDTH_DESKTOP, []);

  const activateDrawer = useCallback((content: ReactNode) => {
    setContent(content);
  }, []);

  const deactivateDrawer = useCallback(() => {
    setContent(undefined);
  }, []);

  const value = useMemo(
    () => ({
      drawerWidth,
      activateDrawer,
      deactivateDrawer,
    }),
    [drawerWidth, activateDrawer, deactivateDrawer],
  );

  return (
    <DrawerContext.Provider value={value}>
      <AuthenticatedTemplate>
        <Drawer drawerWidth={drawerWidth}>{content}</Drawer>
      </AuthenticatedTemplate>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => useContext(DrawerContext);
