import chargingEventsImageUrl from 'app/context/TourContext/assets/charging-events.png';
import createVehicleCandidateImageUrl from 'app/context/TourContext/assets/create-vehicle-candidate.png';
import depotManagementImageUrl from 'app/context/TourContext/assets/depot-management.png';
import manageDepotInfrastructureImageUrl from 'app/context/TourContext/assets/manage-depot-infrastructure.png';
import presentationConfiguratorImageUrl from 'app/context/TourContext/assets/presentation-configurator.png';
import vehicleCandidatesImageUrl from 'app/context/TourContext/assets/vehicle-candidates.png';
import vehicleConfigurationImageUrl from 'app/context/TourContext/assets/vehicle-configuration.png';
import { LightningAltIcon, ShowcaseIcon } from '@optimization/ssi-common';
import { ReactNode } from 'react';
import { TdsIcon } from '@scania/tegel-react';

interface OnboardingStep {
  header: string;
  body: string;
  imageUrl?: string;
  inModal: boolean;
  modalIcon?: ReactNode;
}

const onboardingSteps: OnboardingStep[] = [
  {
    header: 'Solution created',
    body: 'Welcome to your customer’s future, electrified solution. Explore the solution, and its various functions with a guided tour. Would you like to get started?',
    inModal: false,
  },
  {
    header: 'Add vehicle candidates',
    body: 'Start off by adding vehicle candidates to your solution. Input chassis numbers to base the candidates off of already existing vehicles, their basic execution and operation, or define them manually for a more agnostic flow.',
    imageUrl: createVehicleCandidateImageUrl,
    inModal: true,
    modalIcon: <TdsIcon name="truck" size="32px" />,
  },
  {
    header: 'List vehicle candidates',
    body: 'Understand the vehicle candidate’s daily range feasibility, product launch period and configuration. Adjust the performance step which specifies the year the customer should order the vehicle.',
    imageUrl: vehicleCandidatesImageUrl,
    inModal: true,
    modalIcon: <TdsIcon name="truck" size="32px" />,
  },
  {
    header: 'Adjust and assess vehicle definition',
    body: 'Assess and adjust the vehicle candidates basic execution, operational factors and potentially defined charging events. If the candidate was created from a chassis number, you can also analyze the historical chassis’ stops.',
    imageUrl: vehicleConfigurationImageUrl,
    inModal: true,
    modalIcon: <TdsIcon name="truck" size="32px" />,
  },
  {
    header: 'Create new depot',
    body: 'Create depots and add them to your solution. Setup depots by defining some basic information such as grid power capacity and the site’s base power demand, map your vehicle candidates, and start adding depot charging infrastructure.',
    imageUrl: depotManagementImageUrl,
    inModal: true,
    modalIcon: <LightningAltIcon sizeClassName="w-32 h-32" />,
  },
  {
    header: 'Define charging events',
    body: 'Once your vehicle candidates are mapped to their respective home depots, and if required, begin setting up their charging events in order for them to complete their daily operations.',
    imageUrl: chargingEventsImageUrl,
    inModal: true,
    modalIcon: <LightningAltIcon sizeClassName="w-32 h-32" />,
  },
  {
    header: 'Adapt infrastructure',
    body: 'Based on potentially added charging events, the chargers, their power and number of satellites, might need to be adapted.',
    imageUrl: manageDepotInfrastructureImageUrl,
    inModal: true,
    modalIcon: <LightningAltIcon sizeClassName="w-32 h-32" />,
  },
  {
    header: 'Filter and create presentation',
    body: 'Filter the vehicle candidates you wish to include in the solution presentation. Proceed directly to the presentation or copy the link to revisit the presentation at a later time.',
    imageUrl: presentationConfiguratorImageUrl,
    inModal: true,
    modalIcon: <ShowcaseIcon sizeClassName="w-32 h-32" />,
  },
  {
    header: 'View detailed information',
    body: 'For more detailed information on the various functionalities in Scania Analytics, please visit the documentation located at the bottom of the navigation bar.',
    inModal: false,
  },
];

export default onboardingSteps;
