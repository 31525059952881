import { SetFormValue } from '..';
import { useCallback } from 'react';

interface Props {
  attribute: string;
  setFormValue: SetFormValue;
}

const useOnChangeFieldValue = ({ attribute, setFormValue }: Props) =>
  useCallback(
    (value: string) => {
      setFormValue(attribute, value);
    },
    [attribute, setFormValue],
  );

export default useOnChangeFieldValue;
