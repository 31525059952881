import { financialEvaluationVehicleTypeOptions, TcoVehicleType, TcoVehicleVM } from '@optimization/sa-common';
import { FormValues } from '..';
import { roundTo } from '@optimization/ssi-common';

export const convertResponseToFormValues = (vehicle: TcoVehicleVM) => {
  let comparisonConsumption = vehicle.Consumption ? roundTo(vehicle.Consumption, 2).toString() : '25';

  const defaultFormValues: FormValues = {
    currency: 'EUR',
    yearsOfOwnership: vehicle.EightyPercentSoH ? (Math.floor(10 * vehicle.EightyPercentSoH) / 10).toString() : '0',
    bevInsuranceCost: '0',
    bevTaxesCost: '0',
    bevRoadToll: '0',
    bevMaintenanceCost: '0',
    bevEngineType: 'BEV',
    bevEnergyCost: '0',
    bevFinanceType: 'Cash',
    bevVehiclePrice: '0',
    bevInterestRate: '0',
    bevResidual: '0',
    bevGovernmentSubsidies: '0',
    comparisonInsuranceCost: '0',
    comparisonTaxesCost: '0',
    comparisonRoadtoll: '0',
    comparisonMaintenanceCost: '0',
    comparisonAdBlueCost: '0',
    comparisonAdBlueConsumption: '0.1',
    comparisonEngineType: vehicle.FuelType ?? TcoVehicleType[TcoVehicleType.Diesel],
    comparisonEnergyCost: '0',
    comparisonEnergyConsumption: comparisonConsumption,
    comparisonFinanceType: 'Cash',
    comparisonVehiclePrice: '0',
    comparisonInterestRate: '0',
    comparisonResidual: '0',
    comparisonGovernmentSubsidies: '0',
  };

  if (vehicle.TcoCalculations !== undefined) {
    const formValues: FormValues = {
      currency: vehicle.TcoCalculations.Currency,
      yearsOfOwnership: vehicle.TcoCalculations.YearsOfOwnership.toString(),
      bevEngineType: 'BEV',
      bevFinanceType: vehicle.TcoCalculations!.BevVehicle!.FinancingType ?? 'Cash',
      bevVehiclePrice: vehicle.TcoCalculations.BevVehicle!.VehiclePrice.toString(),
      bevInterestRate: vehicle.TcoCalculations.BevVehicle!.InterestRate.toString(),
      bevResidual: vehicle.TcoCalculations.BevVehicle!.ResidualValue.toString(),
      bevGovernmentSubsidies: vehicle.TcoCalculations.BevVehicle!.GovernmentSubsidies.toString(),
      bevEnergyCost: vehicle.TcoCalculations.BevVehicle!.EnergyCost.toString(),
      bevInsuranceCost: vehicle.TcoCalculations.BevVehicle!.InsurancePerMonth.toString(),
      bevRoadToll: vehicle.TcoCalculations.BevVehicle!.RoadTollPerMonth.toString(),
      bevTaxesCost: vehicle.TcoCalculations.BevVehicle!.TaxPerMonth.toString(),
      bevMaintenanceCost: vehicle.TcoCalculations.BevVehicle!.MonthlyMaintenance.toString(),
      comparisonEngineType:
        financialEvaluationVehicleTypeOptions.find(
          (x) => x.value === vehicle.TcoCalculations?.ComparisonVehicle?.EngineType,
        )?.value ?? 'Diesel',
      comparisonFinanceType: vehicle.TcoCalculations?.ComparisonVehicle?.FinancingType ?? 'Cash',
      comparisonVehiclePrice: vehicle.TcoCalculations?.ComparisonVehicle?.VehiclePrice.toString() ?? '10000',
      comparisonInterestRate: vehicle.TcoCalculations?.ComparisonVehicle?.InterestRate.toString() ?? '0',
      comparisonResidual: vehicle.TcoCalculations?.ComparisonVehicle?.ResidualValue.toString() ?? '0',
      comparisonGovernmentSubsidies: vehicle.TcoCalculations?.ComparisonVehicle?.GovernmentSubsidies.toString() ?? '0',
      comparisonEnergyCost: vehicle.TcoCalculations?.ComparisonVehicle?.EnergyCost.toString() ?? '0',
      comparisonEnergyConsumption: vehicle.TcoCalculations?.ComparisonVehicle?.EnergyConsumption.toString() ?? '25',
      comparisonAdBlueCost: vehicle.TcoCalculations?.ComparisonVehicle?.AdBlueCost?.toString() ?? '0',
      comparisonAdBlueConsumption: vehicle.TcoCalculations?.ComparisonVehicle?.AdBlueConsumption?.toString() ?? '0',
      comparisonInsuranceCost: vehicle.TcoCalculations?.ComparisonVehicle?.InsurancePerMonth.toString() ?? '0',
      comparisonRoadtoll: vehicle.TcoCalculations?.ComparisonVehicle?.RoadTollPerMonth.toString() ?? '0',
      comparisonTaxesCost: vehicle.TcoCalculations?.ComparisonVehicle?.TaxPerMonth.toString() ?? '0',
      comparisonMaintenanceCost: vehicle.TcoCalculations?.ComparisonVehicle?.MonthlyMaintenance.toString() ?? '0',
    };
    return formValues;
  }
  return defaultFormValues;
};
