import classes from './style.module.scss';
import { ProgressBar } from '@optimization/ssi-common';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  scrollProgressBarRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const ScrollProgressBar = ({ scrollProgressBarRef }: Props) => {
  const [progressPercent, setProgressPercent] = useState(0);

  const handleScroll = useCallback(() => {
    setProgressPercent(Math.min(((window.innerHeight + window.scrollY) / document.body.offsetHeight) * 100, 100));
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <ProgressBar
      className={classes['scroll-progress-bar']}
      ref={scrollProgressBarRef}
      progressPercent={progressPercent}
    />
  );
};

export default ScrollProgressBar;
