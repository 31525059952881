import classes from './style.module.scss';
import classNames from 'classnames';
import useOnChangeFieldValue from '../useOnChangeFieldValue';
import { Dropdown, TextField } from '@optimization/ssi-common';
import { FormValues, SetFormValue } from '../..';
import { useMemo } from 'react';
import { TcoVehicleVM, financialEvaluationVehicleTypeOptions } from '@optimization/sa-common';
import useCurrecyOptions from './useCurrencyOptions';
import useAggregateMultipleValues from '../useAggregateMultipleValues';
import { convertStringToNumber } from '../convertFormValuesToResponse';

interface Props {
  disabled?: boolean;
  className?: string;
  vehicles: TcoVehicleVM[];
  formValues: FormValues;
  setFormValue: SetFormValue;
  isMultiple: boolean;
}

const GeneralInfoFields = ({ className, vehicles, disabled, setFormValue, formValues, isMultiple }: Props) => {
  const currencyOptions = useCurrecyOptions();

  const onChangeBevCompare = useOnChangeFieldValue({
    attribute: 'bevEngineType',
    setFormValue,
  });

  const onChangeDieselCompare = useOnChangeFieldValue({
    attribute: 'comparisonEngineType',
    setFormValue,
  });

  const onChangeCurrency = useOnChangeFieldValue({
    attribute: 'currency',
    setFormValue,
  });

  const onChangeYearsOfOwnerShip = useOnChangeFieldValue({
    attribute: 'yearsOfOwnership',
    setFormValue,
  });
  const yearsOfOwnership = useAggregateMultipleValues(vehicles, 'EightyPercentSoH', true);
  const dailyDistance = useAggregateMultipleValues(vehicles, 'GenericDailyOdometerKm');

  const bevOptions = useMemo(() => financialEvaluationVehicleTypeOptions.filter((x) => x.value === 'Bev'), []);
  const nonBevOptions = useMemo(() => financialEvaluationVehicleTypeOptions.filter((x) => x.value !== 'Bev'), []);
  const nonBevValue = useMemo(
    () => nonBevOptions.find((x) => x.value === formValues.comparisonEngineType),
    [formValues.comparisonEngineType, nonBevOptions],
  );
  const invalidYearsSelected = useMemo(
    () =>
      !isMultiple &&
      (formValues.yearsOfOwnership === '0' ||
        (vehicles[0].EightyPercentSoH &&
          convertStringToNumber(formValues.yearsOfOwnership) > vehicles[0].EightyPercentSoH)),
    [formValues.yearsOfOwnership, isMultiple, vehicles],
  );

  const roundedDownYearsOfOwnership = useMemo(
    () => (Math.floor(10 * convertStringToNumber(formValues.yearsOfOwnership)) / 10).toString(),
    [formValues.yearsOfOwnership],
  );
  return (
    <div className={classNames(classes['general-info-fields'], className)}>
      <div className={classNames('tds-detail-04', classes['label-1'])}>Currency</div>
      <Dropdown
        className={classes['field-1-1']}
        placeholder="Select currency"
        size="lg"
        filter
        disabled={disabled}
        options={currencyOptions}
        value={formValues.currency}
        onChange={onChangeCurrency}
      />
      <div className={classNames('tds-detail-04', classes['label-2'])}>Daily distance</div>
      <TextField
        className={classes['field-2-1']}
        type={isMultiple ? 'text' : 'number'}
        value={dailyDistance}
        size="lg"
        suffixText="KM"
        disabled
        noMinWidth
      />
      <div className={classNames('tds-detail-04', classes['label-3'])}>Years of ownership</div>
      <TextField
        className={classes['field-3-1']}
        type={isMultiple ? 'text' : 'number'}
        value={isMultiple ? yearsOfOwnership : roundedDownYearsOfOwnership}
        size="lg"
        suffixText="Years"
        disabled={isMultiple}
        state={invalidYearsSelected ? 'error' : 'default'}
        helper={
          invalidYearsSelected
            ? `Ownership must be less than or equal to ${vehicles[0].EightyPercentSoH ? Math.floor(100 * vehicles[0].EightyPercentSoH) / 100 : ''} years`
            : undefined
        }
        noMinWidth
        label={''}
        onChange={onChangeYearsOfOwnerShip}
      />
      <div className={classNames('tds-detail-04', classes['label-4'])}>Compare vehicles</div>
      <Dropdown
        className={classes['field-4-1']}
        placeholder="Select"
        size="lg"
        disabled={disabled}
        options={bevOptions}
        value="Bev"
        onChange={onChangeBevCompare}
      />
      <Dropdown
        className={classes['field-4-2']}
        placeholder="Select"
        size="lg"
        disabled={disabled}
        options={nonBevOptions}
        value={nonBevValue?.value ?? ''}
        onChange={onChangeDieselCompare}
      />
    </div>
  );
};

export default GeneralInfoFields;
