import { TcoVehicleVM, VehicleEnhanced } from '@optimization/sa-common';

const convertVehicleToTcoVehicle = (vehicle: VehicleEnhanced) => {
  let fuelConsumption;
  switch (true) {
    case vehicle.ExecutedVehicleProduct?.ComparisonConsumptionLPer100Km !== undefined:
      fuelConsumption = vehicle.ExecutedVehicleProduct.ComparisonConsumptionLPer100Km!;
      break;
    case vehicle.ScaniaChassis?.Consumption?.ConsumptioPerKm !== undefined:
      fuelConsumption = 100 * vehicle.ScaniaChassis?.Consumption?.ConsumptioPerKm!;
      break;
    default:
      fuelConsumption = 25;
  }

  const tcoVehicle: TcoVehicleVM = {
    Id: vehicle.Id,
    TotalEc: vehicle.enhanced.totEc,
    TcoCalculations: vehicle.TcoCalculations,
    EightyPercentSoH: vehicle.ExecutedVehicleProduct?.EightyPercentSoH,
    FuelType: vehicle.ScaniaChassis?.Consumption?.FuelType,
    Consumption: fuelConsumption,
    GenericDailyOdometerKm: vehicle.ExecutedVehicleProduct?.GenericDailyOdometerKm,
  };

  return tcoVehicle;
};

export default convertVehicleToTcoVehicle;
