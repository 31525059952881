export const APP_TITLE = import.meta.env.VITE_APP_TITLE;
export const APP_USP = import.meta.env.VITE_APP_USP;

export const MULTIPLE_VALUES_SEPARATOR = ',';

export const MAX_CABLE_POWER_KW = 325;

export const SITE_HEADER_HEIGHT = 60;

export const TYPE_DESIGNATION_MODULAR = '';

export const SUPPORT_NAME = 'Jakob Petersén';
export const SUPPORT_EMAIL = 'jakob.petersen@scania.com';

export const MODE = import.meta.env.MODE;

export const MOCKING_ENABLED = import.meta.env.VITE_MOCKING_ENABLED === 'true';

export const THEME_COLOR = import.meta.env.VITE_THEME_COLOR;

export const SOLUTION_ENDPOINT = import.meta.env.VITE_SOLUTION_ENDPOINT;

export const MAPBOX_ENDPOINT = import.meta.env.VITE_MAPBOX_ENDPOINT;

export const PRESENTATION_APP_URL = import.meta.env.VITE_PRESENTATION_APP_URL;

export const APP_IS_UPDATED_STORAGE_KEY = 'appIsUpdated';

export const MAPBOX_API_KEY = import.meta.env.VITE_MAPBOX_API_KEY;

export const PERFORMANCE_STEP_TOOLTIP =
  "The vehicle candidate's performance step corresponds to the year in the customer electrification plan";

export const WINTER_MIN_TEMP_DEFAULT = -5;
export const SUMMER_MAX_TEMP_DEFEAULT = 20;

export const GTW_MIN_DEFAULT = 10000;
export const GTW_MAX_DEFAULT = 50000;
