import classes from './style.module.scss';
import classNames from 'classnames';
import EnergyCostsFields from './utils/EnergyCostsFields';
import GeneralInfoFields from './utils/GeneralInfoFields';
import OperationalCostsFields from './utils/OperationalCostsFields';
import ScrollProgressBar from './utils/ScrollProgressBar';
import StepInfo from './utils/StepInfo';
import useLoadingText from 'common/hooks/useLoadingText';
import useScrollToTop from './utils/useScrollToTop';
import VehicleCostsFields from './utils/VehicleCostsFields';
import { Button, Modal } from '@optimization/ssi-common';
import { convertFormValuesToResponse } from './utils/convertFormValuesToResponse';
import { convertResponseToFormValues } from './utils/convertResponseToFormValues';
import { energyCostsInfo, generalInfo, operationalCostsInfo, vehicleCostsInfo } from 'app/config/tco';
import { Loading, useToast } from '@optimization/ssi-common';
import { TcoFinancingMethod, TcoVehicleType } from '@optimization/sa-common';
import {
  useCalculateVehicleTcoFiguresQuery,
  useUpdateMultipleVehiclesMutation,
  useUpdateVehicleTcoFiguresMutation,
} from 'app/services/solution';
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useElementController } from 'app/context/ElementControllerContext';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import {
  TcoVehicleVM,
  ChartTooltip,
  FinancialEvaluationToCosts,
  FinancialEvaluationFromCosts,
  financialEvaluationVehicleTypeOptions,
  TcoCalculationsV2VM,
} from '@optimization/sa-common';
import { convertMultipleResponseToFormValues } from './utils/convertMultipleResponseToFormValues';

export interface FormValues {
  currency: string;
  yearsOfOwnership: string;
  bevInsuranceCost: string;
  bevTaxesCost: string;
  bevRoadToll: string;
  bevMaintenanceCost: string;
  bevEngineType: string;
  bevEnergyCost: string;
  bevFinanceType: string;
  bevVehiclePrice: string;
  bevInterestRate: string;
  bevResidual: string;
  bevGovernmentSubsidies: string;
  comparisonInsuranceCost: string;
  comparisonTaxesCost: string;
  comparisonRoadtoll: string;
  comparisonMaintenanceCost: string;
  comparisonAdBlueCost: string;
  comparisonAdBlueConsumption: string;
  comparisonEngineType: string;
  comparisonEnergyCost: string;
  comparisonEnergyConsumption: string;
  comparisonFinanceType: string;
  comparisonVehiclePrice: string;
  comparisonInterestRate: string;
  comparisonResidual: string;
  comparisonGovernmentSubsidies: string;
}

export type Mode = 'setup-evaluation' | 'edit-evaluation' | 'copy-settings';

export type SetFormValue = (attribute: string, value: string) => void;

type ModalType = 'save-and-exit' | 'clear-all-fields';

interface Props {
  solutionId: string;
  vehicles: TcoVehicleVM[];
  depotId?: string;
  cancelSetupEvaluation: () => void;
  setEvaluationIsDone?: () => void;
  onMultipleClose: () => void;
}

const SetupEvaluation = ({
  solutionId,
  vehicles,
  cancelSetupEvaluation,
  setEvaluationIsDone,
  onMultipleClose,
}: Props) => {
  useScrollToTop();
  const showToast = useToast();

  const elementController = useElementController();
  const tooltipId = useId();

  const scrollProgressBarRef = useRef<HTMLDivElement | null>(null);
  const summaryRef = useRef<HTMLDivElement | null>(null);
  const createDepotRef = useRef<HTMLDivElement | null>(null);

  const isMultiple = useMemo(() => vehicles.length !== 1, [vehicles]);
  const initialApiValues: TcoCalculationsV2VM = useMemo(() => {
    let comparisonConsumption = 0;
    let yearsOfOwnership = 0;
    if (!isMultiple) {
      const vehicle = vehicles[0];
      yearsOfOwnership = vehicle.EightyPercentSoH ?? 0;
      comparisonConsumption = vehicle.Consumption ?? 25;
    }

    const fuelType = isMultiple ? TcoVehicleType[TcoVehicleType.Diesel] : vehicles[0].FuelType;
    return {
      Currency: 'EUR',
      ShowInPresentationApp: true,
      YearsOfOwnership: yearsOfOwnership,
      BevVehicle: {
        EngineType: TcoVehicleType[TcoVehicleType.Bev],
        FinancingType: TcoFinancingMethod[TcoFinancingMethod.Cash],
        VehiclePrice: 0,
        InterestRate: 0,
        ResidualValue: 0,
        GovernmentSubsidies: 0,
        EnergyCost: 0,
        EnergyConsumption: 0,
        InsurancePerMonth: 0,
        TaxPerMonth: 0,
        RoadTollPerMonth: 0,
        MonthlyMaintenance: 0,
      },
      ComparisonVehicle: {
        EngineType: fuelType ?? TcoVehicleType[TcoVehicleType.Diesel],
        FinancingType: TcoFinancingMethod[TcoFinancingMethod.Cash],
        VehiclePrice: 0,
        InterestRate: 0,
        ResidualValue: 0,
        GovernmentSubsidies: 0,
        EnergyCost: 0,
        EnergyConsumption: comparisonConsumption,
        AdBlueConsumption: isMultiple ? 0 : 0.1,
        InsurancePerMonth: 0,
        TaxPerMonth: 0,
        RoadTollPerMonth: 0,
        MonthlyMaintenance: 0,
      },
    };
  }, [isMultiple, vehicles]);

  const initialformValues = useCallback(() => {
    if (isMultiple) return convertMultipleResponseToFormValues(vehicles);
    else return convertResponseToFormValues(vehicles[0]);
  }, [isMultiple, vehicles]);

  const [formValues, setFormValues] = useState<FormValues>(initialformValues);
  const [updateVehicle, updateVehicleState] = useUpdateVehicleTcoFiguresMutation();

  const [calculateResidual, setCalculateResidual] = useState<boolean>(false);

  const configureApiValues = useMemo(() => {
    const multipleApiValueAggregate: TcoCalculationsV2VM = {
      Currency: 'SEK',
      YearsOfOwnership: 0,
      ShowInPresentationApp: vehicles.every((v) => v.TcoCalculations?.ShowInPresentationApp === false) ? false : true,
      BevVehicle: {
        EngineType: TcoVehicleType[TcoVehicleType.Bev],
        FinancingType: vehicles.every(
          (v) => v.TcoCalculations?.BevVehicle?.FinancingType === TcoFinancingMethod[TcoFinancingMethod.Leasing],
        )
          ? TcoFinancingMethod[TcoFinancingMethod.Leasing]
          : TcoFinancingMethod[TcoFinancingMethod.Cash],
        VehiclePrice: 0,
        InterestRate: 0,
        ResidualValue: 0,
        GovernmentSubsidies: 0,
        EnergyCost: 0,
        EnergyConsumption: 0,
        InsurancePerMonth: 0,
        TaxPerMonth: 0,
        RoadTollPerMonth: 0,
        MonthlyMaintenance: 0,
      },
      ComparisonVehicle:
        isMultiple &&
        initialApiValues.ComparisonVehicle &&
        vehicles.some((v) => v.TcoCalculations?.ComparisonVehicle !== undefined)
          ? {
              ...initialApiValues.ComparisonVehicle!,
              EngineType: vehicles.every(
                (v) => v.TcoCalculations?.ComparisonVehicle?.EngineType === TcoVehicleType[TcoVehicleType.NaturalGas],
              )
                ? TcoVehicleType[TcoVehicleType.NaturalGas]
                : TcoVehicleType[TcoVehicleType.Diesel],
              FinancingType: vehicles.every(
                (v) =>
                  v.TcoCalculations?.ComparisonVehicle?.FinancingType ===
                  TcoFinancingMethod[TcoFinancingMethod.Leasing],
              )
                ? TcoFinancingMethod[TcoFinancingMethod.Leasing]
                : TcoFinancingMethod[TcoFinancingMethod.Cash],
            }
          : undefined,
    };

    if (isMultiple) return multipleApiValueAggregate;
    else {
      const vehicle = vehicles[0];
      return vehicle.TcoCalculations ? { ...vehicle.TcoCalculations } : { ...initialApiValues };
    }
  }, [initialApiValues, isMultiple, vehicles]);

  const [apiValues, setApiValues] = useState<TcoCalculationsV2VM>(configureApiValues);

  const setFormValue = useCallback(
    (attribute: string, value: string) => {
      setFormValues((prev) => ({ ...prev, [attribute]: value }));
    },
    [setFormValues],
  );

  const vehicleCalculation = useCalculateVehicleTcoFiguresQuery(
    {
      solutionId: solutionId,
      vehicleId: vehicles[0].Id,
      ReturnResidualValue: calculateResidual,
      Currency: apiValues.Currency,
      YearsOfOwnership: apiValues.YearsOfOwnership,
      ShowInPresentationApp: vehicles[0].TcoCalculations?.ShowInPresentationApp ?? true,
      BevTcoFigures: apiValues.BevVehicle!,
      ComparisonTcoFigures: apiValues.ComparisonVehicle,
    },
    {
      skip:
        isMultiple ||
        !apiValues.BevVehicle ||
        (apiValues.BevVehicle.FinancingType === 'Leasing' && apiValues.BevVehicle.InterestRate === 0) ||
        (apiValues.ComparisonVehicle &&
          apiValues.ComparisonVehicle.FinancingType === 'Leasing' &&
          apiValues.ComparisonVehicle.InterestRate === 0) ||
        apiValues.BevVehicle?.VehiclePrice === 0 ||
        apiValues.BevVehicle?.VehiclePrice <= apiValues.BevVehicle?.ResidualValue ||
        (apiValues.ComparisonVehicle &&
          apiValues.ComparisonVehicle?.VehiclePrice <= apiValues.ComparisonVehicle?.ResidualValue) ||
        !apiValues.YearsOfOwnership,
    },
  );

  const { refetch, isUninitialized } = vehicleCalculation;

  const [updateMultipleVehicles, updateMultipleVehiclesState] = useUpdateMultipleVehiclesMutation();

  useEffect(() => {
    if (calculateResidual && formValues.comparisonResidual) {
      const bevResidual = vehicleCalculation.data?.Content.VehicleTcoResponseInstances.find(
        (e) => e.EngineType === 'Bev',
      )?.ResidualValue;

      if (bevResidual) {
        setFormValues((prev) => ({ ...prev, bevResidual: bevResidual.toFixed(0) }));

        if (
          formValues.bevResidual === bevResidual.toFixed(0) &&
          (apiValues.ComparisonVehicle?.ResidualValue.toString() ?? '0' === formValues.comparisonResidual)
        )
          setCalculateResidual(false);
      }
    }
  }, [
    apiValues.ComparisonVehicle?.ResidualValue,
    formValues.bevResidual,
    formValues.comparisonResidual,
    calculateResidual,
    vehicleCalculation.data?.Content.VehicleTcoResponseInstances,
  ]);

  useEffect(() => {
    if (
      (formValues.bevFinanceType === 'Leasing' && formValues.bevInterestRate === '0') ||
      (formValues.comparisonFinanceType === 'Leasing' && formValues.comparisonInterestRate === '0') ||
      formValues.bevVehiclePrice === '0'
    )
      return;

    setApiValues(convertFormValuesToResponse(vehicles[0], formValues, isMultiple));

    if (!isUninitialized) refetch();
  }, [formValues, isMultiple, isUninitialized, refetch, vehicles]);

  const [modal, setModal] = useState<ModalType | null>(null);

  useEffect(() => {
    if (elementController) {
      elementController.setEnableSidebar(false);
    }

    return () => {
      if (elementController) {
        elementController.setEnableSidebar(true);
      }
    };
  }, [elementController]);

  const showSaveAndExitModal = useCallback(() => {
    setModal('save-and-exit');
  }, []);

  const saveAndExit = useCallback(() => {
    if (
      (formValues.bevFinanceType === 'Leasing' && formValues.bevInterestRate === '0') ||
      (formValues.comparisonFinanceType === 'Leasing' && formValues.comparisonInterestRate === '0')
    ) {
      showToast({
        header: 'Interest Rate cannot be set to 0',
        subheader: 'Please ensure a valid interest rate is entered before saving',
        variant: 'error',
        dataTestid: 'toast-validate-financial-evaluation',
      });
      return;
    }
    const eightyPercentSoH = vehicles[0].EightyPercentSoH;
    if (!isMultiple && eightyPercentSoH && apiValues.YearsOfOwnership > eightyPercentSoH) {
      showToast({
        header: `Years of Ownership must be less than or equal to ${Math.floor(10 * eightyPercentSoH) / 10} years`,
        subheader: `Please enter an ownership duration that is lower than ${Math.floor(10 * eightyPercentSoH) / 10} years`,
        variant: 'error',
        dataTestid: 'toast-validate-financial-evaluation-years',
      });
      return;
    }
    const newRequestValues = convertFormValuesToResponse(vehicles[0], formValues, isMultiple);

    if (isMultiple)
      updateMultipleVehicles({
        solutionId: solutionId,
        Ids: vehicles.map((v) => v.Id),
        VehicleTcos: {
          Currency: apiValues.Currency,
          ShowInPresentationApp: apiValues.ShowInPresentationApp,
          BevTcoFigures: apiValues.BevVehicle,
          ComparisonTcoFigures: apiValues.ComparisonVehicle,
        },
      });
    else {
      const vehicle = vehicles[0];
      if (newRequestValues.BevVehicle)
        updateVehicle({
          solutionId,
          vehicleId: vehicle.Id,
          ReturnResidualValue: false,
          Currency: newRequestValues.Currency,
          YearsOfOwnership: newRequestValues.YearsOfOwnership,
          ShowInPresentationApp: vehicle.TcoCalculations?.ShowInPresentationApp ?? true,
          BevTcoFigures: newRequestValues.BevVehicle,
          ComparisonTcoFigures: newRequestValues.ComparisonVehicle,
        });
    }
  }, [apiValues, formValues, isMultiple, showToast, solutionId, updateMultipleVehicles, updateVehicle, vehicles]);

  useEffect(() => {
    if (updateVehicleState.isSuccess || updateMultipleVehiclesState.isSuccess) {
      showToast({
        header: 'Financial evaluation saved successfully',
        dataTestid: 'toast-saved-financial-evaluation',
      });

      if (!isMultiple && setEvaluationIsDone) setEvaluationIsDone();

      onMultipleClose();
    }
  }, [
    updateVehicleState,
    showToast,
    setEvaluationIsDone,
    updateMultipleVehiclesState.isSuccess,
    isMultiple,
    vehicles,
    onMultipleClose,
  ]);

  useEffect(() => {
    if (
      apiValues.BevVehicle?.ResidualValue !== 0 &&
      apiValues.BevVehicle?.VehiclePrice !== 0 &&
      apiValues.ComparisonVehicle?.ResidualValue !== 0 &&
      apiValues.ComparisonVehicle?.VehiclePrice !== 0 &&
      ((apiValues.BevVehicle && apiValues.BevVehicle?.ResidualValue >= apiValues.BevVehicle?.VehiclePrice) ||
        (apiValues.ComparisonVehicle &&
          apiValues.ComparisonVehicle?.ResidualValue >= apiValues.ComparisonVehicle?.VehiclePrice))
    )
      showToast({
        header: 'Unable to calculate TCO',
        subheader: 'Please enter a Residual Value that is lower than the vehicle cost',
        variant: 'error',
      });
  }, [apiValues, showToast]);

  useEffect(() => {
    const eightyPercentSoH = vehicles[0].EightyPercentSoH;
    if (!isMultiple && eightyPercentSoH && apiValues.YearsOfOwnership > eightyPercentSoH)
      showToast({
        header: 'Unable to calculate TCO',
        subheader: `Please enter an ownership duration that is lower than ${Math.floor(10 * eightyPercentSoH) / 10} years`,
        variant: 'error',
      });
  }, [apiValues, isMultiple, showToast, vehicles]);

  const showClearAllFieldsModal = useCallback(() => {
    setModal('clear-all-fields');
  }, []);

  const hideModal = useCallback(() => {
    setModal(null);
  }, []);

  const clearAllFields = useCallback(() => {
    hideModal();

    const formValues = isMultiple
      ? convertMultipleResponseToFormValues(vehicles)
      : convertResponseToFormValues(vehicles[0]);

    setApiValues(configureApiValues);
    setFormValues(formValues);

    showToast({
      header: 'All settings cleared',
    });
  }, [configureApiValues, hideModal, isMultiple, showToast, vehicles]);

  useMakeElementsSticky({
    scrollProgressBarRef,
    summaryRef,
  });

  const fromCostsHeader = useMemo(() => {
    const queryData = vehicleCalculation.data?.Content;
    let ComparisonVehicle = queryData?.VehicleTcoResponseInstances.find((x) => x.EngineType !== 'Bev');
    if (vehicleCalculation.isSuccess && ComparisonVehicle) {
      const fromOption = financialEvaluationVehicleTypeOptions.find((x) => x.value === ComparisonVehicle!.EngineType);

      if (fromOption) {
        return fromOption.name;
      }
    }

    return financialEvaluationVehicleTypeOptions.find((x) => x.value === formValues.comparisonEngineType)!.name;
  }, [formValues.comparisonEngineType, vehicleCalculation.data?.Content, vehicleCalculation.isSuccess]);

  const toTotalCosts = useMemo(() => {
    if (vehicleCalculation.isSuccess && vehicleCalculation.data.Content) {
      const calculations = vehicleCalculation.data.Content;
      return calculations.VehicleTcoResponseInstances.find((e) => e.EngineType === 'Bev');
    }
  }, [vehicleCalculation]);

  const fromTotalCosts = useMemo(() => {
    if (vehicleCalculation.isSuccess && vehicleCalculation.data.Content) {
      const calculations = vehicleCalculation.data.Content;
      return calculations.VehicleTcoResponseInstances.find((e) => e.EngineType !== 'Bev');
    }
  }, [vehicleCalculation]);

  const isLoading =
    updateVehicleState.isLoading || vehicleCalculation.isLoading || updateMultipleVehiclesState.isLoading;
  const isError = updateVehicleState.isError || vehicleCalculation.isError || updateMultipleVehiclesState.isError;

  const loadingText = useLoadingText({
    isSavingEvaluation: updateVehicleState.isLoading || updateMultipleVehiclesState.isLoading,
    isCalculatingEvaluation: vehicleCalculation.isLoading,
  });

  return (
    <div className={classNames(classes['setup-evaluation'], 'tds-container')}>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      {modal === 'save-and-exit' && (
        <Modal
          size="xs"
          header="Save and exit"
          variant="primary"
          disabled={isLoading}
          ctaConfirmSubmitDataTestid="button-save-financial-evaluation"
          ctaConfirmText="Save and exit"
          ctaConfirmSubmit={saveAndExit}
          onClose={hideModal}
        >
          {isMultiple
            ? 'Are you sure you want to exit? Fields left set to zero will not overwrite existing values.'
            : 'Are you sure you want to exit? All current settings will be saved.'}
        </Modal>
      )}
      {modal === 'clear-all-fields' && (
        <Modal
          size="xs"
          header="Clear all fields"
          variant="danger"
          ctaConfirmText="Remove values"
          ctaConfirmSubmit={clearAllFields}
          onClose={hideModal}
        >
          Are you sure you want to remove all added values from the fields?
        </Modal>
      )}
      <ScrollProgressBar scrollProgressBarRef={scrollProgressBarRef} />
      <ChartTooltip tooltipId={tooltipId} hidden absolute />
      <div className={classes.summary} ref={summaryRef}>
        <div className={classes.inner}>
          <div className={classes.navigation}>
            <Button
              ref={createDepotRef}
              text="Save and exit"
              disabled={isLoading}
              dataTestid="button-save-and-exit-financial-evaluation"
              onClick={showSaveAndExitModal}
            />
            <Button text="Cancel" variant="secondary" disabled={isLoading} onClick={cancelSetupEvaluation} />
          </div>
          {!isMultiple && (
            <div className={classes.costs}>
              <FinancialEvaluationToCosts
                vehicleCosts={toTotalCosts?.VehicleCostsPercent ?? 0}
                energyCosts={toTotalCosts?.EnergyCostsPercent ?? 0}
                operationalCosts={toTotalCosts?.OperationalCostsPercent ?? 0}
                totalCost={Math.round(toTotalCosts?.TotalCostOfOwnership ?? 0)}
                isEmpty={false}
                tooltipId={tooltipId}
                currency={formValues?.currency || 'EUR'}
              />
              <FinancialEvaluationFromCosts
                vehicleCosts={fromTotalCosts?.VehicleCostsPercent ?? 0}
                energyCosts={fromTotalCosts?.EnergyCostsPercent ?? 0}
                operationalCosts={fromTotalCosts?.OperationalCostsPercent ?? 0}
                totalCost={Math.round(fromTotalCosts?.TotalCostOfOwnership ?? 0)}
                isEmpty={!Boolean(apiValues.ComparisonVehicle?.EngineType)}
                header={fromCostsHeader}
                tooltipId={tooltipId}
                currency={formValues?.currency || 'EUR'}
              />
            </div>
          )}
          <button
            onClick={showClearAllFieldsModal}
            className={classNames('reset-button-styles', classes['clear-all-fields'])}
          >
            Clear all fields
          </button>
        </div>
      </div>
      <div className={classNames('mt-spacing-48', classes.steps)}>
        <div className={classes.step}>
          <StepInfo header={generalInfo.header} description={generalInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <GeneralInfoFields
                disabled={isLoading}
                vehicles={vehicles}
                formValues={formValues}
                setFormValue={setFormValue}
                isMultiple={isMultiple}
              />
            </div>
          </div>
        </div>
        <div className={classes.step}>
          <StepInfo header={vehicleCostsInfo.header} description={vehicleCostsInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <VehicleCostsFields
                setCalculateResidual={setCalculateResidual}
                isMultiple={isMultiple}
                disabled={isLoading}
                fromCostsHeader={fromCostsHeader}
                formValues={formValues}
                setFormValue={setFormValue}
              />
            </div>
          </div>
        </div>
        <div className={classes.step}>
          <StepInfo header={energyCostsInfo.header} description={energyCostsInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <EnergyCostsFields
                disabled={isLoading}
                fromCostsHeader={fromCostsHeader}
                vehicles={vehicles}
                formValues={formValues}
                setFormValue={setFormValue}
                isMultiple={isMultiple}
              />
            </div>
          </div>
        </div>
        <div className={classes.step}>
          <StepInfo header={operationalCostsInfo.header} description={operationalCostsInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <OperationalCostsFields
                disabled={isLoading}
                fromCostsHeader={fromCostsHeader}
                formValues={formValues}
                setFormValue={setFormValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupEvaluation;
