import classes from './style.module.scss';
import classNames from 'classnames';
import useOnChangeFieldValue from '../useOnChangeFieldValue';
import { FormValues, SetFormValue } from '../..';
import { DebouncedTextField } from '@optimization/ssi-common';
import { useMemo } from 'react';

interface Props {
  className?: string;
  disabled?: boolean;
  fromCostsHeader: string;
  formValues: FormValues;
  setFormValue: SetFormValue;
}

const OperationalCostsFields = ({ className, disabled, fromCostsHeader, formValues, setFormValue }: Props) => {
  const onChangeBevInsuranceCost = useOnChangeFieldValue({
    attribute: 'bevInsuranceCost',
    setFormValue,
  });

  const onChangeBevTaxesCost = useOnChangeFieldValue({
    attribute: 'bevTaxesCost',
    setFormValue,
  });

  const onChangeBevRoadToll = useOnChangeFieldValue({
    attribute: 'bevRoadToll',
    setFormValue,
  });

  const onChangeBevMaintenance = useOnChangeFieldValue({
    attribute: 'bevMaintenanceCost',
    setFormValue,
  });

  const onChangeDieselInsuranceCost = useOnChangeFieldValue({
    attribute: 'comparisonInsuranceCost',
    setFormValue,
  });

  const onChangeDieselTaxesCost = useOnChangeFieldValue({
    attribute: 'comparisonTaxesCost',
    setFormValue,
  });

  const onChangeDieselRoadToll = useOnChangeFieldValue({
    attribute: 'comparisonRoadtoll',
    setFormValue,
  });

  const onChangeDieselMaintenance = useOnChangeFieldValue({
    attribute: 'comparisonMaintenanceCost',
    setFormValue,
  });

  const suffixText = useMemo(
    () => (window.innerWidth >= 608 ? `${formValues.currency}/month` : `${formValues.currency}/mon`),
    [formValues.currency],
  );

  return (
    <div className={classNames(classes['operational-costs-fields'], className)}>
      <div className={classNames(classes['header-1'], 'tds-detail-04')}>BEV</div>
      <div className={classNames(classes['header-2'], 'tds-detail-04')}>{fromCostsHeader}</div>
      <div className={classNames('tds-detail-04', classes['label-1'])}>Insurance</div>
      <DebouncedTextField
        className={classes['field-1-1']}
        type="number"
        value={formValues.bevInsuranceCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevInsuranceCost}
        label=""
      />
      <DebouncedTextField
        className={classes['field-1-2']}
        type="number"
        value={formValues.comparisonInsuranceCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselInsuranceCost}
        label=""
      />
      <div className={classNames('tds-detail-04', classes['label-2'])}>Taxes</div>
      <DebouncedTextField
        className={classes['field-2-1']}
        type="number"
        value={formValues.bevTaxesCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevTaxesCost}
        label=""
      />
      <DebouncedTextField
        className={classes['field-2-2']}
        type="number"
        value={formValues.comparisonTaxesCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselTaxesCost}
        label=""
      />
      <div className={classNames('tds-detail-04', classes['label-3'])}>Road toll</div>
      <DebouncedTextField
        className={classes['field-3-1']}
        type="number"
        value={formValues.bevRoadToll}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevRoadToll}
        label=""
      />
      <DebouncedTextField
        className={classes['field-3-2']}
        type="number"
        value={formValues.comparisonRoadtoll}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselRoadToll}
        label=""
      />
      <div className={classNames('tds-detail-04', classes['label-4'])}>Maintenance</div>
      <DebouncedTextField
        className={classes['field-4-1']}
        type="number"
        value={formValues.bevMaintenanceCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevMaintenance}
        label=""
      />
      <DebouncedTextField
        className={classes['field-4-2']}
        type="number"
        value={formValues.comparisonMaintenanceCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselMaintenance}
        label=""
      />
    </div>
  );
};

export default OperationalCostsFields;
