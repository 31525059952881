import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { MAPBOX_API_KEY, MAPBOX_ENDPOINT } from 'app/config';

interface ReverseGeocodingArgs {
  longitude: number;
  latitude: number;
}

interface ReverseGeocodingResponse {
  type: string;
  features: {
    geometry: { coordinates: number[]; type: string };
    id: string;
    properties: {
      context: {
        country?: {
          country_code: string;
          country_code_alpha_3: string;
          mapbox_id: string;
          name: string;
          wikidata_id: string;
        };
        place?: { mapbox_id: string; name: string; wikidata_id: string };
        postcode?: { mapbox_id: string; name: string };
        region?: {
          mapbox_id: string;
          name: string;
          region_code: string;
          region_code_full: string;
          wikidata_id: string;
        };
        street?: { mapbox_id: string; name: string };
      };
      coordinates: {
        latitude: number;
        longitude: number;
        routable_points?: { latitude: number; longitude: number; name: string }[];
      };
      feature_type: string;
      full_address?: string;
      mapbox_id: string;
      name?: string;
      name_preferred?: string;
      place_formatted?: string;
    };
    type: string;
  }[];
  attribution: string;
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${MAPBOX_ENDPOINT}/search/geocode/v6`,
});

const baseQueryRetry = retry(baseQuery, {
  maxRetries: 0,
});

export const mapboxApi = createApi({
  reducerPath: 'mapboxApi',
  baseQuery: baseQueryRetry,
  endpoints: (build) => ({
    reverseGeocoding: build.query<ReverseGeocodingResponse, ReverseGeocodingArgs>({
      query: ({ latitude, longitude }) => ({
        url: `reverse?latitude=${latitude}&longitude=${longitude}&access_token=${MAPBOX_API_KEY}`,
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 7,
    }),
  }),
});

export const { useReverseGeocodingQuery } = mapboxApi;
