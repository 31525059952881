import SetupEvaluation from './SetupEvaluation';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useExpandVehicleAndTab } from '../ExpandVehicleAndTabContext';
import { useParams } from 'react-router-dom';
import { TcoVehicleVM, VehicleEnhanced } from '@optimization/sa-common';
import convertVehicleToTcoVehicle from './convertVehicleToTcoVehicle';

interface FinancialEvaluation {
  startSetupEvaluation: (vehicle: VehicleEnhanced) => void;
}

const FinancialEvaluationContext = createContext<FinancialEvaluation | null>(null);

export const FinancialEvaluationProvider = ({ children }: { children: ReactNode }) => {
  const { solutionId } = useParams();
  const [vehicle, setVehicle] = useState<TcoVehicleVM | null>(null);

  const expandVehicleAndTab = useExpandVehicleAndTab();

  const expandVehicleAndTabCallback = useCallback(() => {
    if (expandVehicleAndTab) {
      expandVehicleAndTab.setExpandVehicleAndTab({ vehicleId: vehicle?.Id, candidateTab: 'Financial evaluation' });
    }
  }, [expandVehicleAndTab, vehicle?.Id]);

  const cancelSetupEvaluation = useCallback(() => {
    setVehicle(null);
    expandVehicleAndTabCallback();
  }, [expandVehicleAndTabCallback]);

  const startSetupEvaluation = useCallback((vehicleParam: VehicleEnhanced) => {
    const convertedVehicle = convertVehicleToTcoVehicle(vehicleParam);
    setVehicle(convertedVehicle);
  }, []);

  const setEvaluationIsDone = useCallback(() => {
    setVehicle(null);
    expandVehicleAndTabCallback();
  }, [expandVehicleAndTabCallback]);

  const value = useMemo(
    () => ({
      startSetupEvaluation,
    }),
    [startSetupEvaluation],
  );

  return (
    <FinancialEvaluationContext.Provider value={value}>
      {vehicle && solutionId ? (
        <SetupEvaluation
          solutionId={solutionId}
          vehicles={[vehicle]}
          cancelSetupEvaluation={cancelSetupEvaluation}
          setEvaluationIsDone={setEvaluationIsDone}
          onMultipleClose={() => {}}
        />
      ) : (
        children
      )}
    </FinancialEvaluationContext.Provider>
  );
};

export const useFinancialEvaluation = () => useContext(FinancialEvaluationContext);
