import { CandidateTabs } from 'features/candidate/Candidates/Table/Detail';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

interface ExpandVehicleAndTab {
  vehicleId?: number;
  candidateTab?: CandidateTabs;
  setExpandVehicleAndTab: ({ vehicleId, candidateTab }: { vehicleId?: number; candidateTab?: CandidateTabs }) => void;
}

const ExpandVehicleAndTabContext = createContext<ExpandVehicleAndTab | null>(null);

export const ExpandVehicleProvider = ({ children }: { children: ReactNode }) => {
  const [vehicleId, setVehicleId] = useState<number>();
  const [candidateTab, setCandidateTab] = useState<CandidateTabs>();

  const setExpandVehicleAndTab = useCallback(
    ({ vehicleId, candidateTab }: { vehicleId?: number; candidateTab?: CandidateTabs }) => {
      setVehicleId(vehicleId);
      setCandidateTab(candidateTab);
    },
    [],
  );

  const value = useMemo(
    () => ({
      vehicleId,
      candidateTab,
      setExpandVehicleAndTab,
    }),
    [vehicleId, candidateTab, setExpandVehicleAndTab],
  );

  return <ExpandVehicleAndTabContext.Provider value={value}>{children}</ExpandVehicleAndTabContext.Provider>;
};

export const useExpandVehicleAndTab = () => useContext(ExpandVehicleAndTabContext);
