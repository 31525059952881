import classes from './style.module.scss';
import classNames from 'classnames';
import { ReactNode, useEffect } from 'react';

interface Props {
  children: ReactNode;
  drawerWidth: number;
}

const Drawer = ({ children, drawerWidth }: Props) => {
  useEffect(() => {
    document.body.style.overflow = children ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [children]);

  return (
    <>
      {children && <div className={classes.overlay} />}
      <div
        style={{ width: `${drawerWidth}px` }}
        className={classNames(classes.drawer, { [classes['is-active']]: Boolean(children) })}
      >
        {children}
      </div>
    </>
  );
};

export default Drawer;
